import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Samples_widget from "../Samples_widget/Samples_widget";
import * as Constant from "../Constant";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [expandedBlogs, setExpandedBlogs] = useState({}); // Track expanded state for each blog

  const fetchBlogs = async (pageNumber, signal) => {
    try {
      const response = await fetch(`${Constant.Blogs}?page=${pageNumber}`, { signal });
      const data = await response.json();
      console.log("API Response:", data);
      if (data.success && Array.isArray(data.data.data)) {
        setBlogs((prevBlogs) => [...prevBlogs, ...data.data.data]);
        setHasMore(data.data.data.length > 0);
      } else {
        setBlogs([]);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching blog data", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchBlogs(page, signal);

    return () => {
      controller.abort(); // Abort the fetch request on unmount
    };
  }, [page]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Function to truncate text to two lines
  const truncateText = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  // Function to toggle expanded state for a blog
  const toggleExpand = (blogId) => {
    setExpandedBlogs((prev) => ({
      ...prev,
      [blogId]: !prev[blogId], // Toggle expanded state
    }));
  };

  if (loading && page === 1) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <>
      <div className="container-fluid bg-primary mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "400px" }}>
          <h1 className="display-3 fw-bold text-white">Blogs</h1>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <Link className="text-white" to="/">Home</Link>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">Blog</p>
          </div>
        </div>
      </div>

      <section className="blog-posts-area section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 post-list blog-post-list">
              {blogs.length > 0 ? (
                blogs.map((blog, index) => {
                  const isExpanded = expandedBlogs[blog.id]; // Check if the blog is expanded
                  const description = isExpanded
                    ? blog.description
                    : truncateText(blog.description, 100); // Truncate description

                  return (
                    <div className="single-heading" key={index}>
                      <img className="img-fluid" src={blog.image || "defaultImage.jpg"} alt={blog.title || "Blog Image"} />
                      <h3 className="mt-3">{blog.title}</h3>
                      <p
                        className="text-justify mt-2"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => toggleExpand(blog.id)}
                      >
                      </button>
                      <Link to={`/blogs_sub/${blog.slug}`}>
                        <button type="button" className="btn btn-primary mt-2 py-2 px-4">View Details</button>
                      </Link>
                      <hr className="mt-3 mb-3" />
                    </div>
                  );
                })
              ) : (
                <p className="text-center">No blogs available.</p>
              )}
              {hasMore && (
                <div className="text-center">
                  <button 
                    onClick={handleLoadMore} 
                    className="btn btn-primary mt-4"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Load More'}
                  </button>
                </div>
              )}
            </div>
            <div className="col-lg-4 sidebar">
              <Samples_widget />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;