import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Samples_widget from '../Samples_widget/Samples_widget';
import blogimg from '../../assets/img/Best_essay_writing_Service.png';

function Blogs_sub() {
  const { slug } = useParams(); // Get the slug from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`https://admin.assignmentserviceaustralia.com/api/blog_details/${slug}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog details');
        }
        const data = await response.json();
        if (data.success) {
          setBlog(data.data);
        } else {
          setError('Blog not found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [slug]);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  if (!blog) {
    return <div className="text-center">No blog found.</div>;
  }

  return (
    <>
      {/* <!-- Header Start --> */}
      <div className="container-fluid bg-primary mb-5">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h1 className="display-3 font-weight-bold text-white">Blogs</h1>
          <div className="d-inline-flex text-white">
            <p className="m-0">
              <Link className="text-white" to="/">
                Home
              </Link>
            </p>
            <p className="m-0 px-2">/</p>
            <p className="m-0">
              <Link className="text-white" to="/blogs">
                Blog
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section className="blog-posts-area section-gap blog-details-image">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 post-list blog-post-list"
              id="container-content-wrapper"
            >
              <div className="single-post img-size700">
                <h2>{blog.title}</h2>
                <br />
                <img
                  className="img-fluid"
                  src={blog.image || blogimg}
                  alt={blog.title}
                  style={{ width: "100%" }}
                />
                <div className="content-wrap">
                  <div className="post">
                    <p
                      dangerouslySetInnerHTML={{ __html: blog.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs_sub;